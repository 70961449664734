const transalation = {
  Search: "Search",
  Cancel: "Cancel",
  Schedule: "Schedule",
  StartNow: "Start Now",
  Online: "Online",
  Busy: "Busy",
  Logout: "Logout",
  NoResultsFound: "No results found",
  Yes: "Yes",
  No: "No",
  OopsSomethingWentWrong: "Oops, Something went wrong",
  Deny: "Deny",
  Join: "Join",
  MeetingID: "Meeting ID",
  JoinMeeting: "Join Meeting",
  Calls: "Call",
  Create: "Create",
  Invite: "Invite",
  Add: "Add",
  Remove: "Remove",
  Edit: "Edit",
  Delete: "Delete",
  Share: "Share",
  Date: "Date",
  Time: "Time",
  Set: "Set",
  SignUp: "Sign up",
  Page: "page",
  SignIn: "Sign in",
  Password: "Password",
  LogOut: "Log Out",
  Start: "Start",
  Allow: "Allow",
  Reject: "Reject",
  Accept: "Accept",
  NetworkError: "Make sure you are connected to a network",
  with: "with",
  by: "by",
  ScreenSharedBy: "Screen Shared by ",
  Dashboard: {
    TodaysMeeting: "Today's Meeting",
    UserIsLoggedout: "User Logged Out!",
    OK: "OK",
    StartaMeeting: "Start",
    EnterMeetingID: "Enter Meeting ID",
    EnterPassword: "Enter password",
    Join: "Join",
    SafariMsg:
      "Your browser settings is blocking the meeting window.Please enable popup window from safari settings to view.",
    LogoutMsg: "Are you sure to logout ",
    AllMeetings: "All meetings",
    ListAll: "List All",
    MeetinginProgress: "Meeting in progress",
    OpenMeetings: "Open Meetings",
    Today: "Today",
    Yesterday: "Yesterday",
    Tomorrow: "Tomorrow",
    Invite: "Invite",
    Copied: "Copied",
    Copy: "Copy",
    StartsIn: "Starts in",
    Minutes: "minutes",
    YouHaveNoUpcomingMeetings: "You have no upcoming meetings...",
    ScheduleMeeting: "Schedule Meeting",
    EditMsg: "Are you sure want to Edit this meeting ?",
    EditSingleOccurrence: "This Occurrence",
    EditAllOccurrence: "Upcoming Occurrences",
    InvitePeople: "Invite People",
    EmailPlaceholder: "Email (Institute email prefered)",
    AddEmail: "Add Email",
    EmailSentSuccsessfully: "Email sent succsessfully",
    MeetingDeleteMsg: "Meeting is successfully deleted",
    MeetingNotDeleteMsg: "Meeting is not deleted",
    DeleteMsg: " Are you sure want to delete this meeting ?",
    DeleteSingleOccurrence: " Delete single occurrence",
    DeleteAllOccurrence: "Delete all occurrence",
    Calendar: "Calendar",
    TopicError: "Topic field cannot be empty",
    TopicMaxLenError: "Topic length limit exceeded",
    DiscriptionError: "Description length limit exceeded",
    TopicMinLenError: "Topic length should be greater than two letters.",
    TopicNumError: "Topic should not contain numbers and special characters",
    TopicBlankError: "Topic field cannot be blank",
    TimeError: "Don't enter past and present time",
    OccurenceError: "Don't enter occurence less than two",
    PleaseMarkDays: "Please mark days",
    MeetingEditedMsg: "Meeting is successfully edited",
    MeetingNotEditedMsg: "Meeting is not edited",
    MeetingCreatedMsg: "Meeting is successfully created",
    MeetingNotCreatedMsg: "Meeting is not created",
    Topic: "Topic *",
    MyMeeting: "My meeting",
    Description: "Description",
    EnterDescription: "Enter Description",
    When: " When *",
    OnStart: "On Start",
    RepeatEvery: "Repeat every",
    Recurring: "Recurring",
    Day: "Day",
    Week: "Week",
    Month: "Month",
    OccursOn: " Occurs on",
    Ofthemonth: "of the month",
    EndDate: "End date",
    After: "After",
    Occurrences: "Occurrences",
    History: "History",
    RecurringMeeting: "Recurring",
    HoolvaRoot: "Hoolva Root",
    ProfilePicture: "Profile picture",
    Replace: "Replace",
    Upload: "Upload",
    Settings: "Settings",
    General: "General",
    Profile: "Profile",
    AudioVideo: "Audio & Video",
    Notification: "Notification",
    Themes: "Themes",
    About: "About",
    Security: "Security",
    NotificationPopup: "Notification Popup",
    NotificationSound: "Notification Sound",
  },
  Chat: {
    PinnedBy: "Pinned by",
    Pin: "Pin",
    UnPin: "Unpin",
    Recent: "Recent",
    Searched: "Searched",
    IsCalling: "is calling",
    Messages: "Messages",
    NewChat: "New Chat",
    Groups: "Groups",
    StartConversation: "Start Conversation",
    CreateGroup: "Create Group",
    GroupName: "Group Name",
    Description: "Description",
    SearchMembers: "Search members",
    GrpNameErrorMsg: "Maximum character allowed for group name is 30.",
    InviteUser: "Invite User",
    SearchUsers: "Search Users",
    AddMembers: "Add Members",
    SearchMembersToAdd: "Search members to add",
    Admin: "Admin",
    Inactive: "Inactive",
    RemoveAsAdmin: "Remove as admin",
    AddAsAdmin: "Add as admin",
    ArchiveGroup: "Archive Group",
    LeaveGroup: " Leave Group",
    LastSeen: "Last seen",
    HoursAgo: "hours ago",
    Ago: "ago",
    LastSeenAFewSecondsAgo: "Last seen a few seconds ago",
    NewMessages: "New Messages",
    Loading: "Loading",
    StartAConversation: "Start a conversation with @",
    YouWereInACall: "You were in a call for",
    YouHaveMissedCall: "You have a missed call from ",
    YourCallWasNotAnswered: "Your call was not answered",
    UnreadMessages: "Unread messages",
    LimitedToMaximum: "Limited to maximum ",
    Characters: "characters",
    TextYouAreTryingToPasteIs: "Text you are trying to paste is",
    CharactersMoreThanExpectedLimit: "characters more than expected limit",
    FileMaxSizeError: "Maximum size of the file has to be 100 MB.",
    CannotPasteAFolder: "Cannot paste a folder.",
    IsTyping: "is typing",
    Message: "Message",
    UploadFailed: "Upload Failed",
    DragRelease: "Drag & Release",
    Reply: "Reply",
    ShareMessage: "Share Message",
    ThisMessageHasBeenDeleted: "This message has been deleted",
    You: "You",
    DeleteMessage: " Delete Message",
    DeleteConfirmation: "Are you sure you want to delete this message?",
    Video: "Video",
    Update: "Update",
    In10minutes: " In 10 minutes",
    LaterToday: "Later today",
    TomorrowMorning: "Tomorrow morning",
    NextWeek: " Next week",
    NextMonth: "Next month",
    Custom: "Custom",
    SearchResultFor: "Search Result for",
    Members: "Members",
    Direct: "Direct Messages",
    AllMessages: "All Messages",
  },
  Call: {
    CallPopupMsg: "currently opened in the popout window",
    CallInfo: "callInfo",
    ScreenSharePausedBy: "Screen share paused by ",
    AwaitingMembersToJoin: "Awaiting members to join",
    Awaiting: "Awaiting",
    ToJoin: "to join",
    Disconnect: "Disconnect",
    HostMsg: "Your meeting Host has ended the meeting",
    CallingYou: "Calling you...",
    AlreadyInCall: "You are already in a call with",
    AcceptPrompt: "Accepting this call would end your existing call",
    IncomingCallFrom: "Incoming call from",
    BrowerNotSupport: "Your browser does not support the audio element.",
  },
  Notifications: {
    JoinTheMeeting: "requested to join the meeting",
    NewMsg: "New message received.",
    ToEveryone: "to Everyone.",
    SharedAnImage: "shared an image",
    SharedAFile: "shared a file",
    RaisedHand: "raised hand.",
    NotSupporting: "This browser does not support desktop notification",
    NewMsgFrom: "You have a new message from",
    NewMsgIn: "New message in",
    Notifications: "Notifications",
    Notify: "Notify Me",
    allMsg: "All new messages",
    DirectMsg: "Direct Messages",
    Dnd: "Do Not Disturb",
    allowNotification: "Allow Notification",
    from: "From",
    to: "To",
    enableNotification: "Enable Specific Notification",
    Groups: "Groups",
    Select: "Select",
    previewMsg: "Preview of all messages",
    MuteNotification: "Mute all sounds from this app",
    IncomingChat: "Incoming Chat",
    OutgoingChat: "Outgoing Chat",
    IncomingGroup: "Group Chat",
    IncomingCall: "Incoming Call Notification",
    exclude: "Exclude",
  },
  Dial: {
    ChooseMsg: "Choose your Audio Connectivity",
    ComputerAudio: "Computer Audio",
    DialIn: "Dial In",
    DialOut: "Dial Out",
    PassCode: "Pass Code",
    DialByYourLocation: "Dial by your location",
    Microphone: "Microphone",
    Speaker: "Speaker",
    SwitchAudio: "Switch Audio",
    return: "return",
    JoinMeeting: "Join Meeting",
  },
  Meeting: {
    AudioIssues: "Audio Issues",
    IHeardEcho: "I heard echo",
    ICouldntHearOneParticipant: "I could’nt hear one participant",
    VideoIssues: "Video Issues",
    VideoWasFrozen: "Video was frozen",
    Flickering: "Participant’s video was flickering",
    AccountVerification: "Account Verification",
    EmailText:
      "We have send an email to tom@xbox.com. Please verify your account through that link on email.",
    WrongEmail: "Wrong email? Go back to",
    ResendEmail: "Resend  email",
    UsernameOr: "Username /Meeting code",
    Username: 'Username',
    ForgotPassword: "Forgot password?",
    LoginWith: "Login with",
    LoginError: "Invalid username or password",
    CollaborateMsg: 'Collaborate and work from anywhere.',
    WelcomeBack: "Welcome",
    WelcomeMsg:
      "You are just a few clicks away from a great meeting experience.",
    DontHaveAnAccount: "Don’t have an account?",
    FirstName: "First name",
    LastName: "Last name",
    CreatePassword: "Create password",
    Email: "Email (Institute email prefered)",
    ByClicking: "By clicking on Sign Up , you agree to the Hoolva",
    Terms: "Terms",
    And: "And",
    PrivacyPolicy: "Privacy Policy",
    CreateAnAccount: "Create an account",
    HoolvaMsg:
      "is your partner in remote meetings. You can make video and audio calls, message participants, take notes, assign tasks and schedule meetings.",
    AlreadyAMember: "Already a member?",
    StartAMeeting: "Start a meeting",
    BrandMsg:
      "is an easy tool to call, chat exchange ideas within your team and outside",
    NewMeeting: "New Meeting",
    InvalidPassword: "Invalid Meeting Password",
    InvalidID: "Invalid Meeting ID",
    NetworkError: "Make sure you are connected to a network.",
    EnterMeetingID: "Enter Meeting ID",
    ClearHistory: "Clear History",
    EnterPassword: "Enter password",
    YourVideoIsOff: "Your video is off",
    TestMic: "Test Mic",
    Playing: "Playing",
    YourDisplayName: "Your display name",
    EnterEmail: "Enter your email ID (optional)",
    AllowMic: "Allow your mic and video",
    PermissionText:
      "Hoolva want to access your mic and camera to continue the meeting. Select Allow to give the permission.",
    BreakoutRoom: "Breakout Room",
    Chat: "Chat",
    Members: "Members",
    Mic: "Mic",
    More: "More",
    Reactions: "Reactions",
    ShareNow: "Share now",
    ScreenshareResumeStop: "Screenshare Resume/Stop",
    ScreensharePauseStop: "Screenshare Pause/Stop",
    Video: "Video",
    WhiteBoard: "White Board",
    LongMsgAlert: "You'll need to shorten your message to send it.",
    AllChat: "All chat",
    You: "You",
    ChooseMembers: "Choose Members",
    All: "All",
    Everyone: "Everyone",
    Room: "Room",
    RecentChats: "Recent chats",
    Host: "Host",
    MailNotSend: "Something went wrong, Mail not send.",
    InviteMailSendSuccessfully: "Invite mail send successfully.",
    InvitePeople: "Invite People",
    Copied: "Copied",
    CopyMeetingURL: "Copy meeting URL",
    MembersWaitingToJoin: "Members waiting to join",
    AllowAll: "Allow All",
    Settings: "Settings",
    General: "General",
    SortBy: "Sort By",
    Participant: "Participant (A-Z)",
    MeetingjoinTime: "Meeting join time",
    ParticipantsUnmuted: " Participants (Unmuted)",
    ParticipantsVideoOn: "Participants (Video On)",
    RemoveMsg: "Do you want to remove this person from the meeting?",
    MuteAll: "Mute All",
    Record: "Record",
    StoreRecord: "Where do you want to store this recording?",
    HoolvaCloud: "Hoolva Cloud",
    StopRecording: "Do you want to Stop Recording?",
    Stop: "Stop",
    StopRecord: "Stop Record",
    ConnectByPhone: "Connect by Phone",
    ShowVideoTile: "Show Video Tile",
    HideVideoTile: "Hide Video Tile",
    FullScreen: "Full Screen",
    ExitFullScreen: "Exit Full Screen",
    ScreenSharePausedBy: "Screen share paused by",
    ScreenShare: "Screen Share",
    EndForMe: "End for me",
    EndForAll: "End for all",
    MeetingID: "Meeting ID",
    Password: "Password",
    Pin: "Pin",
    MeetingInfo: "Meeting Info",
    Resume: "Resume",
    Rec: "Rec",
    StopRec: "Stop Rec",
    YourScreenIsBeingShared: "Your screen is being shared",
    Locked: "Locked",
    DropFileHere: "Drop file here",
    Or: "or",
    SelectFile: "Select file",
    Uploading: "Uploading",
    AllowMembersToDownload: "Allow members to download",
    AllowMembersToNavigate: "Allow members to navigate",
    RestrictAccess: "Restrict Access",
    StopShare: " Stop Share",
    WhiteboardIsInProgress: "Whiteboard is in progress",
    CloseWhiteBoard: "Close White Board",
    OpenWhiteBoard: "Open White Board",
    AudioVideo: "Audio & Video",
    Camera: "Camera",
    Microphone: "Microphone",
    TestSpeaker: "Test Speaker",
    Language: "Language",
    TopAndBottomBar: "Top and bottom bar",
    SwitchToOneSide: "switch to one side",
    Profile: "profile",
    DisplayName: "Display Name",
    Update: "Update",
    YouRemovedFromMeeting: "You were removed from the meeting.",
    YouRemovedFromCall: "You were removed from the call.",
    HostMutedYourMicrophone: "Host has muted your microphone.",
    HostRequestingToUnmute: "Host is requesting to unmute your microphone",
    HostTurnedoffCamera: "Host has turned off your camera.",
    HostRequestingToStartCamera: "Host is requesting to start your camera.",
    LostConnectionWithServer:
      "Lost connection with the server. Trying to reconnect.",
    SomethingWrongUsingMediaDevices:
      "Something went wrong while using media devices",
    LostConnectionWithServerPleaseJoinAgain:
      "Lost connection to the server. Please join again.",
    WentWrongCameraPleaseCheckYourSettings:
      "Something went wrong with your camera. Please check your settings.",
    OopsOur: "Oops! Our",
    ConnectionIsHavingIssues: "connection is having issues on our side.",
    MaximumAllowedParticipantsLimitHasReached:
      "Maximum allowed participants limit has reached.",
    MeetingParticipantLimitReached:
      "Meeting participant limit has reached for this meeting.",
    AccountLimitReached:
      "The maximum number of simultaneous meetings allowed for the account has been reached. Please contact your Administrator.",
    PleaseMakeSureConnectedToInternet:
      "Please make sure you are connected to the internet.",
    IdentifiedSomeProblems:
      "We have identified some problems with your connection. Please check your internet or use a different network",
    AttemptingToReconnect: "Attempting to reconnect",
    ThreeMinutesLeft: "You have only three minutes left in this meeting.",
    MaximumAllowedDurationReached:
      "Your meeting ended since the maximum allowed meeting duration limit has reached.",
    MeetingEnded: "Meeting Ended",
    ThankYouForUsing: "Thank you for using",
    Recording: "Recording",
    TheHostHasEndedTheMeeting: "The host has ended the meeting",
    NotAllowed: "You are not allowed to join this meeting",
    PleaseWait: "Please Wait",
    HostWillLetYouSoonIn: "your host will let you soon in.",
    HostSoonStart: "your host will soon be here to start the meeting.",
    Joining: "Joining",
    LeaveMeeting: "Leave meeting",
    IfUAre: "If you are host, please",
    ToStart: "to start the meeting",
  },
};
export default transalation;