export enum BreakOutActionType {
    SET_CREATE_ROOM_MODAL = "SET_CREATE_ROOM_MODAL",
    SET_ROOM_DURATION_MODAL = "SET_ROOM_DURATION_MODAL",
    SET_ROOM_DURATION = "SET_ROOM_DURATION",
    SET_CUSTOM = "SET_CUSTOM",
    SET_MANAGE_ROOMS_MODAL = "SET_MANAGE_ROOMS_MODAL",
    ROOMS_LIST = "ROOMS_LIST",
    SET_ADD_MEMBER_BOX = "SET_ADD_MEMBER_BOX",
    SET_MEMBERS_LISTS = "SET_MEMBERS_LISTS",
    SET_ROOM = "SET_ROOM",
    FLAG_SET_ROOM="FLAG_SET_ROOM",
    SET_THREE_DOT_MENU = "SET_THREE_DOT_MENU",
    SET_ADD_MEMBER_MODAL = "SET_ADD_MEMBER_MODAL",
    SET_GUEST_JOIN_ROOM_MODAl = "SET_GUEST_JOIN_ROOM_MODAl",
}
