export enum CallActionType {
    SET_CALL_TYPE = 'SET_CALL_TYPE',
    SET_INCOMING_PBX_CALL = 'SET_INCOMING_PBX_CALL',
    SET_SIL_DETAILS = 'SET_SIL_DETAILS',
    SET_CALL_STATE = 'SET_CALL_STATE',
    SET_CALL_TIMER = 'SET_CALL_TIMER',
    SET_CONTACT_LIST = 'SET_CONTACT_LIST',
    SET_ACTIVE_CONTACT = 'SET_ACTIVE_CONTACT',
    SET_CALL_STATUS = 'SET_CALL_STATUS',
    SET_CALL_HISTORY = 'SET_CALL_HISTORY',
    CLEAR_PBX_STORE = 'CLEAR_PBX_STORE',
    CALL_BUTTON_STATE = 'CALL_BUTTON_STATE',
    EXTENSION_UPDATE = 'EXTENSION_UPDATE',
    SET_ACTIVE_CALL = 'SET_ACTIVE_CALL'
}