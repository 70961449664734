export enum FlagActionType {
    SET_MEMBERSLIST = 'SET_MEMBERSLIST',
    IDLESTATE = "IDLESTATE",
    // SET_AUDIO = 'SET_AUDIO',
    // SET_VIDEO = 'SET_VIDEO',
    SET_PUBLISHERSTATE = 'SET_PUBLISHERSTATE',
    LOGIN_STATE = 'LOGIN_STATE',
    CREATE_MEETING = 'CREATE_MEETING',
    SET_PRIVATECHAT = 'SET_PRIVATECHAT',
    SET_FULLSCREEN = 'SET_FULLSCREEN',
    SET_POPUP = 'SET_POPUP',
    SET_ISHOST = "SET_ISHOST",
    HOST_END_FOR_ALL = 'HOST_END_FOR_ALL',
    HOST_MUTED_MIC = "HOST_MUTED_MIC",
    HOST_TURNED_OFF_CAMERA = "HOST_TURNED_OFF_CAMERA",
    HOST_KICKOUT = 'HOST_KICKOUT',
    LOCKED_MEETING = 'LOCKED_MEETING',
    CLEAR_MEETING_FLAGS = 'CLEAR_MEETING_FLAGS',
    SET_GROUPCHAT_STATE = "SET_GROUPCHAT_STATE",
    SET_PAGE_VISIBILITY_STATE = 'SET_PAGE_VISIBILITY_STATE',
    SET_INVITE_MODAL_STATE = 'SET_INVITE_MODAL_STATE',
    SET_DIAL_MODAL = 'SET_DIAL_MODAL',
    SET_SCREENSHARE_PAUSE = 'SET_SCREENSHARE_PAUSE',
    SET_EDIT_SCHEDULEDMEET = "SET_EDIT_SCHEDULEDMEET",
    SET_ALL_RECURRENCES_SCHEDULEDMEET = "SET_ALL_RECURRENCES_SCHEDULEDMEET",
    SET_SINGLE_RECURRENCES_SCHEDULEDMEET = "SET_SINGLE_RECURRENCES_SCHEDULEDMEET",
    SET_EDIT_SINGLE_MEET = 'SET_EDIT_SINGLE_MEET',
    SET_EDIT_SINGLE_REC_MEET = 'SET_EDIT_SINGLE_REC_MEET',
    // NEW_MEETING_FLAG = 'NEW_MEETING_FLAG',
    FLAG_EDIT_MEETING_TIME = "FLAG_EDIT_MEETING_TIME",
    SET_WHITEBOARD_MODAL = 'SET_WHITEBOARD_MODAL',
    // NEW_MEETING_FLAG = 'NEW_MEETING_FLAG',
    SET_FILE_DOWNLOAD = "SET_FILE_DOWNLOAD",
    SET_FILE_NAVIGATE = "SET_FILE_NAVIGATE",
    SET_ON_READY = 'SET_ON_READY',
    SET_LOGROCKET_STATE = "SET_LOGROCKET_STATE",
    SET_WHITEBOARD_SOCKET = "SET_WHITEBOARD_SOCKET",
    SET_PROGRESS = "SET_PROGRESS",
    SET_RECORDING = 'SET_RECORDING',
    SET_PAUSE_RECORDING = 'SET_PAUSE_RECORDING',
    SET_USER_INVITE_MODAL = "SET_USER_INVITE_MODAL"
}